<template>
  <v-container class="staff" grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Staffs Identity Card
            <v-spacer></v-spacer>
            <v-download-column-select
              :hide-download="true"
              option-type="radio"
              v-if="
                ($auth.isAdmin() ||
                  $auth.loggedInRole().slug === 'administrator') &&
                form.items.data.length > 0
              "
              :showPDF="false"
              :downloadColumns="card_designs"
              @selectedItem="selectedCardDesign"
            >
              SELECT TEMPLATE TYPE
            </v-download-column-select>
            &nbsp;

            <!-- <download-button v-if="form.items.data.length" @action="printBulk" -->
            <download-button v-if="form.items.data.length" @action="printStaffCard()"
              >PRINT ID Card</download-button
            >
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :options.sync="pagination"
            :server-items-length="form.items.meta.total"
            :loading="form.loading"
            :footer-props="rowsPerPageItems"

          >
            <!-- footer-props.items-per-page-options="rowsPerPageItems" -->
            <template v-slot:item="{ index, item }">
              <tr>
                <td align="left">{{ index + form.items.meta.from }}</td>
                <td align="left" class="text-xs-left">
                  <v-list-item>
                    <v-list-item-avatar>
                      <img :src="item.profile_image" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <small
                          ><strong>{{ item.full_name }}</strong></small
                        >
                      </v-list-item-title>
                      <v-list-item-subtitle class="grey--text text--lighten-1">
                        <small v-for="(role, ri) in item.roles" :key="ri">
                          <span v-if="ri <= 6"
                            >{{ role.name
                            }}<span v-if="ri < item.roles.length - 1"
                              >,</span
                            ></span
                          >
                        </small>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </td>
                <td class="text-xs-left">
                  <v-icon small color="primary">email</v-icon>
                  {{ item.email || "N/A" }} <br />
                  <v-icon color="warning" small>phone</v-icon>
                  <strong>{{ item.primary_mobile || "N/A" }}</strong>
                </td>
                <td class="text-xs-left">
                  {{ item.created_at }}
                </td>
                <td class="text-xs-right">
                  <add-button
                    @action="printStaffCard(item.id)"
                    :permission="'section-read'"
                    icon="print"
                  >
                    <!-- @action="printSingle(item.id)" -->
                    Print
                  </add-button>
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
     <v-dialog v-model="validateDateDialogue" persistent max-width="290px">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title">Valid Till</span>
        </v-card-title>
        <v-card-text class="pb-1">
          <br />
         
            <v-text-field
              outlined
              label="Valid Till Date"
              v-model="validDate"
              v-mask="'####-##-##'" 
              :rules="dateValidation"
              placeholder="YYYY-MM-DD"
            />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="warning" outlined @click="validateDateDialogue = false,selectedUser='',validDate=''"
            >Close</v-btn
          >
          <v-btn
            small
            outlined
            color="success"
            v-if="print_status == 'single'"
            @click="printSingle()"
            >Print Single
          </v-btn>
          <v-btn small color="success" outlined @click="printBulk()" v-else
            >Print Bulk</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import Form from "@/library/Form";
import AddressHelper from "@/library/AddressHelper";
import { getPropertyFromArrayObject, validateDate, redirectDownloadUrl } from "@/library/helpers";
import Mixins from "@/library/Mixins";

import statesData from "@/assets/json/states.json";
import districtDataData from "@/assets/json/districts.json";
import cityData from "@/assets/json/cities.json";
import { mapState } from "vuex";


export default {
  mixins: [Mixins],
  data: () => ({
    dateValidation: [(v) => validateDate(v) || "Invalid Date Format"],

    validDate:"",
    validateDateDialogue:false,
    print_status:'single',
    filter: false,
    cropDialog: false,
    imgSrc: "",
    cropImg: "",
    uploadCropped: {},
    selectedUser:"",
    // pagination: {
    //   rowsPerPage: 10,
    // },
    rowsPerPageItems: { "items-per-page-options": [5, 10, 25, 50, 75] },
    form: new Form(
      {
        dialog: false,
        image: {},
      },
      "/api/user"
    ),
    imagePicker: {
      name: null,
      image: null,
      url: null,
    },
    search: "",
    statesData,
    districtDataData,
    cityData,
    headers: [
      { text: "#", align: "left", value: "sno", width: 5, sortable: false },
      { text: "Name", align: "left", value: "fname", width: 50 },
      {
        text: "Contact",
        align: "left",
        value: "contact",
        sortable: false,
        width: 50,
      },
      { text: "Associated at", align: "left", value: "created_at", width: 100 },
      { text: "Action", align: "center", sortable: false, width: 20 },
    ],
    updateState: false,

    roles: [],
    role: "",
    roleLoading: false,
    selectedRole: [],
    // card_designs: [
    //   { name: "Default", selected: true, value: "" },
    //   { name: "ID Card 1", selected: false, value: "1" },
    //   { name: "ID Card 2", selected: false, value: "2" },
    // ],
    card_designs : [],
    id_card_template_id:'',
  }),
  watch: {
    pagination: function () {
      this.get();
    },
    "members.staff.filter.role": {
      handler(v) {
        this.get();
      },
    },
  },
  mounted() {
    this.getIdCardDesignName();
  },
  computed: {
    ...mapState(["members"]),
  },
  methods: {
    searchMember: function (v) {
      if (v.length > 2 || !v.length) this.get();
    },
   
    get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
      });
    },

    fetchRoles() {
      this.roleLoading = true;
      this.$rest
        .get("/api/roles?rowsPerPage=50&sortBy=name")
        .then(({ data }) => {
          if (data.data.length) {
            this.roles = data.data;
          }
        })
        .finally(() => {
          this.roleLoading = false;
        });
    },
    printBulk() {
      redirectDownloadUrl({
        uri: "/print/card/staff-card",
        queryString: `id_card_template=${this.id_card_template_id}&valid_till=${this.validDate}`,
      });
      // this.$rest.get("/api/print/staff-id-card").then(({ data }) => {
      //   console.log(data.data.download_url )
      //   let url = data.data.download_url + "?id_card_template=" +this.id_card_template_id+ "&valid_till="+ this.validDate;
      //   window.open(url);
      // });
    },
    printStaffCard(id=null){
      this.validateDateDialogue = true
      if(id){
        this.selectedUser = id
        this.print_status = 'single'
      }else{
        this.print_status = 'bulk'
      }
    },
    printSingle() {
      this.validateDateDialogue = false
      //http://reporting.test/print/card/staff-card/1:24?userId=111&id_card_template=&valid_till=
      redirectDownloadUrl({
        uri: "/print/card/staff-card",
        queryString: `userId=${this.selectedUser}&id_card_template=${this.id_card_template_id}&valid_till=${this.validDate}`,
      });
      // this.$rest.get("/api/print/staff-id-card").then(({ data }) => {
      //   console.log(data.data.download_url);
      //   this.validateDateDialogue = false
      //   let url = data.data.download_url + "?userId=" + this.selectedUser + "&id_card_template=" +this.id_card_template_id + "&valid_till="+ this.validDate;
      //   window.open(url);
      // });
    },
    selectedCardDesign(item) {
      if (item.value != this.id_card_template_id) {
        this.id_card_template_id = item.value;
      } else {
        this.id_card_template_id = "";
      }
    },
    getIdCardDesignName() {
      this.$rest
        .get("api/get-id-card")
        .then(({ data }) => {
          let default_card = {
            id:'',
            id_card_template_id:'',
            name:'Default'
          }
          data.push(default_card);
          this.card_designs = data.map((item) => {
            if (item.id_card_template_id === "1") {
              this.defaultDesign = item;
            }
            return {
              value: item.id_card_template_id,
              name: item.name.substr(0, 10) + "..",
              selected: false,
            };
          });
          this.id_card_template_id = this.defaultDesign.id_card_template_id;
          this.card_designs = this.card_designs.map((item) => {
            if (item.value === this.defaultDesign.id_card_template_id) {
              item.selected = true;
            }
            return item;
          });
        })
        .catch((error) => {});
    },
  },
};
</script>

<style lang="scss">
.staff {
  .v-list__item {
    padding: 0 !important;
  }
  .v-list__item__title {
    height: 15px;
    line-height: 15px;
  }
}

.v-chip .v-avatar {
  margin-right: -10px;
}
</style>
